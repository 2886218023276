import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

const NotFound = () => {
  return (
    <div className="singlePage">
      <div className="notFound">
        <i className="icon-sad" />
        <p>Page is not found</p>
        <Link to="/">Back to home</Link>
      </div>
    </div>
  );
};

export default NotFound;
