import { useEffect, useState } from "react";

const useInfo = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/info.json");
        if (!response.ok) {
          console.error("Failed to fetch data");
        }

        const newData = await response.json();
        sessionStorage.setItem("infoData", JSON.stringify(newData));
        setData(newData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    const storedData = sessionStorage.getItem("infoData");
    if (storedData) {
      setData(JSON.parse(storedData));
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, []);

  return { data, isLoading };
};

export default useInfo;
