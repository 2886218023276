import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import useInfo from "helper/useInfo";
import useMousePosition from "helper/useMousePosition";
import Home from "pages/home";
import Portfolio from "pages/portfolio";
import ScrollToTop from "components/scrollToTop";

function App() {
  const mousePosition = useMousePosition();
  const { isLoading } = useInfo();

  if (isLoading) {
    return null;
  }

  return (
    <Router>
      <ScrollToTop />
      <div className="light" style={{ transform: mousePosition }} />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio/:alias?" element={<Portfolio />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
