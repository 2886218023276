import { Link, useParams } from "react-router-dom";

import useInfo from "helper/useInfo";
import Single from "pages/portfolio/single";

const Home = () => {
  const {
    data: { portfolio = [] }
  } = useInfo();

  const { alias } = useParams();

  if (alias) {
    return <Single alias={alias} />;
  }
  return (
    <div className="singlePage">
      <div className="backBlock">
        <Link to="/" className="back">
          <i className="icon-arrowBack" />
        </Link>
        <h1>Portfolio</h1>
      </div>
      <div id="portfolio">
        <div className="list">
          {portfolio.map(
            ({ name, alias, skills, description, thumb, year, imagePath }) => (
              <div className="item" key={name}>
                {alias && (
                  <Link to={`/portfolio/${alias}`} className="link">
                    {name}
                  </Link>
                )}
                <img
                  alt={name}
                  fetchpriority="high"
                  src={`/portfolio/${imagePath}/${thumb}`}
                />
                <div className="info">
                  <h4>{name}</h4>
                  <p className="year">{year}</p>
                  <p className="description">{description}</p>
                  <ul className="skills">
                    {skills.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
