import classNames from "classnames";
import { Link } from "react-router-dom";
import { ScrollingProvider, SectionLink, Section } from "react-scroll-section";

import useInfo from "helper/useInfo";

const Home = () => {
  const {
    data: { menu = [], experience = [], portfolio = [] }
  } = useInfo();

  return (
    <ScrollingProvider>
      <header>
        <div className="top">
          <div className="info">
            <h1>
              Hello, I am
              <br />
              <b>Karl Avetyan</b>
            </h1>
            <h2>Detail-oriented UI developer</h2>
            <p>
              with a passion for crafting seamless user experiences. With a
              proven track record of delivering high- quality interfaces, I
              excel at paying close attention to every aspect of my work.
            </p>
          </div>
          <div className="navigation">
            {menu.map(({ title, section }) => (
              <SectionLink section={section} key={title}>
                {({ onClick, isSelected }) => (
                  <li
                    onClick={onClick}
                    className={classNames({ active: isSelected })}
                  >
                    <b>{title}</b>
                  </li>
                )}
              </SectionLink>
            ))}
          </div>
        </div>
        <div className="bottom">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/karlavetyan/"
          >
            <i className="icon-linkedin" />
          </a>
          <a target="_blank" rel="noreferrer" href="mailto:karl@karl.am">
            <i className="icon-email" /> <span>karl@karl.am</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://karl.am/cv.pdf">
            <i className="icon-cv" />
            <span>Download CV</span>
          </a>
        </div>
      </header>
      <div className="right-side">
        <Section id="about">
          <h3>About me</h3>
          <p>
            As you already understood from the title of the site, I am
            <b> Karl</b>. I've been deeply passionate about web programming for
            a long time. I'm entirely <b> self-taught</b>, starting my journey
            at the age of 13 by creating websites using website builders. As I
            honed my skills, I progressed to crafting websites from scratch
            using HTML, CSS, JavaScript, and jQuery.
            <br />
            <br />
            Along the way, I discovered another passion of mine:
            <b> television</b>. The inner workings of the TV industry fascinate
            me, and I've always been drawn to its behind-the-scenes dynamics.
            After gaining valuable experience in television for four years, I
            decided to refocus my efforts back on programming.
            <br />
            <br />
            Currently, I'm working with a leading gambling company. Initially, I
            started as a markup specialist and later transitioned to working
            with React. In the programming world, I firmly believe that staying
            <b> up-to-date</b> with the latest advancements is essential.
            <br /> <br />
            Driven by my enthusiasm and constant thirst for knowledge, I am
            committed to evolving as a web programmer. Embracing challenges and
            keeping my <b> finger on the pulse</b> of technology are guiding
            principles that propel me forward in this ever-changing field.
          </p>
        </Section>
        <Section id="experience">
          <h3>Experience</h3>
          <div className="list">
            {experience.map(
              ({ link, years, position, company, skills, description }) => (
                <div className="item" key={company}>
                  <div className="years">{years}</div>
                  <div className="info">
                    <h4>{position}</h4>
                    {link ? (
                      <a
                        href={link}
                        target="_blank"
                        className="company"
                        rel="noreferrer"
                      >
                        {company}
                      </a>
                    ) : (
                      <p className="company">{company}</p>
                    )}
                    <p className="description">{description}</p>
                    <ul className="skills">
                      {skills.map(item => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            )}
          </div>
        </Section>

        <Section id="portfolio">
          <h3>
            last works <Link to="/portfolio">All</Link>
          </h3>
          <div className="list">
            {portfolio
              .slice(0, 3)
              .map(
                ({
                  year,
                  name,
                  alias,
                  thumb,
                  skills,
                  imagePath,
                  description
                }) => (
                  <div className="item" key={name}>
                    {alias && (
                      <Link to={`/portfolio/${alias}`} className="link">
                        {name}
                      </Link>
                    )}
                    <img
                      src={`/portfolio/${imagePath}/${thumb}`}
                      fetchpriority="high"
                      alt={name}
                    />
                    <div className="info">
                      <h4>{name}</h4>
                      <p className="year">{year}</p>
                      <p className="description">{description}</p>
                      <ul className="skills">
                        {skills.map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )
              )}
          </div>
        </Section>
      </div>
    </ScrollingProvider>
  );
};

export default Home;
