import { Link } from "react-router-dom";
import classNames from "classnames";

import useInfo from "helper/useInfo";
import NotFound from "pages/notFound";

const Single = ({ alias }) => {
  const {
    isLoading,
    data: { portfolio = [] }
  } = useInfo();

  const {
    name,
    year,
    link,
    skills,
    images,
    imagesMob,
    imagePath,
    description
  } = portfolio.filter(({ alias: pAlias }) => pAlias === alias)[0] || {};

  if (isLoading) {
    return null;
  }

  if (!name) {
    return <NotFound />;
  }

  return (
    <div className="singlePage">
      <div className="backBlock">
        <Link to="/portfolio" className="back">
          <i className="icon-arrowBack" />
        </Link>
        <h1>
          {name}
          {link && (
            <a href={link} target="_blank" rel="noreferrer">
              <i className="icon-link" />
            </a>
          )}
          <b className="year">({year})</b>
        </h1>
      </div>
      <div className="description">
        {description}
        <ul className="skills">
          {skills.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      {(images || imagesMob) && (
        <div className={classNames("imagesBlock", { hasMobile: imagesMob })}>
          {images && (
            <div className="desktop">
              {imagesMob && <h3>Desktop</h3>}
              {Array(images)
                .fill()
                .map((_, i) => (
                  <picture key={i}>
                    <source
                      type="image/webp"
                      srcSet={`/portfolio/${imagePath}/desktop/${i + 1}.webp`}
                    />
                    <source
                      type="image/png"
                      srcSet={`/portfolio/${imagePath}/desktop/${i + 1}.png`}
                    />
                    <img
                      src={`/portfolio/${imagePath}/desktop/${i + 1}.png`}
                      alt={name}
                    />
                  </picture>
                ))}
            </div>
          )}
          {imagesMob && (
            <div className="mobile">
              <h3>Mobile</h3>
              {Array(imagesMob)
                .fill()
                .map((_, i) => (
                  <picture key={i}>
                    <source
                      type="image/webp"
                      srcSet={`/portfolio/${imagePath}/mobile/${i + 1}.webp`}
                    />
                    <source
                      type="image/png"
                      srcSet={`/portfolio/${imagePath}/mobile/${i + 1}.png`}
                    />
                    <img
                      src={`/portfolio/${imagePath}/mobile/${i + 1}.png`}
                      alt={name}
                    />
                  </picture>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Single;
